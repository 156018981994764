.team-section {
  background-color: rgb(250, 250, 250);
  min-height: 400px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  position: relative;
  padding-top: $xxl * 3;
  padding-bottom: $xxl * 3;

  .team-profile {
    display: flex;
    flex-direction: row;
    margin-top: $m;
    flex-wrap: wrap;
    justify-content: center;

    div {
      padding: $l;
      text-align: center;
      margin-top: $l;
      h2,
      p {
        color: #282626;
      }
      h2 {
        font-size: 27px;
        font-weight: 400;
        padding-top: $l;
      }
      p {
        font-size: 16px;
        max-width: 180px;
        margin: 0 auto;
      }
    }
    img {
      width: 225px;
      height: 225px;
      object-fit: contain;
      border-radius: 225px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .team-section {
    padding-top: $xxl * 2;
    padding-bottom: $xxl * 2;
  }
}
