.qualifications-section {
  min-height: 400px;
  background-image: url("/assets/promo.jpg");
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  position: relative;
  background-attachment: fixed;
  .wrapper {
    z-index: 1;
    h1 {
      color: $altTxt;
      text-shadow: rgba(255, 255, 255, 60%) 1px 1px 1px,
        rgba(0, 0, 0, 60%) -1px -1px 1px;
    }
    .line {
      background-color: $altTxt;
    }
  }

  .qualification-icons {
    display: flex;
    flex-direction: row;
    margin-top: $m;
    flex-wrap: wrap;
    justify-content: center;
    img {
      margin: $l;
      width: 150px;
      height: 150px;
      object-fit: contain;
    }
  }
}

@media only screen and (max-width: 600px) {
  .qualifications-section {
    min-height: 350px;
    .qualification-icons {
      img {
        width: 70px;
        height: 70px;
        margin: $m;
      }
    }
  }
}
