.nft-price-section {
  min-height: 600px;
  background-image: url("/assets/nft-price.jpg");
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  > div {
    background-color: rgba(0, 0, 0, 0.4);
    padding-top: $xxl * 3;
    padding-bottom: $xxl * 3;
    .header {
      h1 {
        color: $atwhite;
        font-weight: 600;
      }
      .line {
        background-color: $atwhite;
        display: flex;
        align-self: flex-end;
        margin: inherit;
        margin-top: $l;
      }
    }
    h1 {
      font-size: 40px;
      font-weight: 600;
    }

    .price-info {
      text-align: right;
      margin-top: $xxl * 1.5;
      h1 {
        font-size: 34px;
        line-height: 40px;
        margin-bottom: $m;
        font-weight: 600;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .nft-price-section {
    min-height: 550px;
    flex-direction: column;
    > div {
      padding-top: $xxl * 1;
      padding-bottom: $xxl * 1;
      > div {
        flex-direction: column;
        padding-right: $xl;
        padding-left: $xl;
        video {
          width: 280px !important;
          margin: 0 auto;
        }
      }
      .header {
        margin-top: $xxl;
      }
    }
  }
}
