.cert-section {
  background-color: $bg;
  padding-top: $xxl * 3;
  padding-bottom: $xxl * 3;
  .wrapper {
    justify-content: space-evenly;
  }
  .cert-img {
    width: 605px;
    height: 605px;
    overflow: hidden;
    img {
      height: 605px;
    }
  }

  .cert-blk {
    text-align: center;
    max-width: 450px;
    ul {
      padding-top: $xxl * 2;
      li {
        color: $txt;
        text-align: left;
        font-size: $large;
        padding-top: $l;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .cert-section {
    padding-top: $xxl * 2;
    padding-bottom: $xxl * 2;
    padding-left: $xl;
    padding-right: $xl;
    .cert-blk {
      max-width: 300px;
      ul {
        padding-top: $xxl;
        padding-bottom: $xxl;
      }
    }

    .cert-img {
      max-width: auto;
      width: 300px;
      height: 300px;
      margin: 0 auto;
      margin-top: $xxl;
      img {
        height: 300px;
        width: 300px;
        margin-left: 12px;
      }
    }
  }
}
