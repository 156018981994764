.promo-section {
  min-height: 600px;
  background-image: url("/assets/promo.jpg");
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  position: relative;
  .wrapper {
    z-index: 1;
  }
  h1 {
    font-size: 32px;
    max-width: 700px;
    text-align: center;
    line-height: 44px;
    font-family: $subTitleFmaily;
  }
  p {
    font-size: 15px;
    margin: $m;
  }
}

@media only screen and (max-width: 600px) {
  .promo-section {
    min-height: auto;
    padding-top: $xxl;
    padding-bottom: $xxl;
    padding-left: $l;
    padding-right: $l;
  }
}
