.menu {
  position: fixed;
  display: flex;
  z-index: 10;
  top: 0;
  width: 100%;
  padding: $l;
  align-items: center;
  justify-content: flex-end;
  background-color: rgba(0,0,0,0.5);

  .web3-btn {
    align-self: flex-end;
    button {
      font-size: $xlarge;
      padding: $xs * 1.5;
      padding-left: $xl;
      padding-right: $xl;
    }
  }

  .social-icons {
    margin-left: $l;
    img {
      width: 30px;
      margin-left: $s;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 600px) {
  .menu {
    width: 100vw;
    .wrapper {
      margin-right: 0;
    }
  }
}