.intro-section {
  background-color: $bg;
  padding-top: $xxl * 3;
  padding-bottom: $xxl * 3;
  .wrapper {
    justify-content: space-evenly;
  }
  .intro-img {
    width: 605px;
    height: 605px;
    overflow: hidden;
    img {
      height: 605px;
    }
  }

  .intro-blk {
    text-align: center;
    max-width: 450px;
    p {
      color: $txt;
      padding-top: $xxl * 2;
      padding-bottom: $xxl * 2;
      line-height: 24px;
    }

    img {
      width: 160px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .intro-section {
    padding-top: $xxl * 2;
    padding-bottom: $xxl * 2;
    padding-left: $xl;
    padding-right: $xl;
    .intro-blk {
      p {
        padding-top: $xxl;
        padding-bottom: $xxl;
        padding-left: $xxl * 2;
        padding-right: $xxl * 2;
      }
    }

    .intro-img {
      width: 280px;
      height: 280px;
      margin: 0 auto;
      margin-top: $xxl;
      img {
        height: 280px;
        width: 280px;
      }
    }
  }
}
