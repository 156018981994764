body {
  overflow-x: hidden;
  background-color: $atblack;
  font-size: $medium;
}

.container {
  width: 100vw;
  height: 100vh;
  // min-width: 1300px;
}

p + p {
  margin: 0;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

button {
  width: auto;
  background: $green;
  border: 1px solid $green;
  color: $atwhite;
  padding: $s;
  border-radius: 5px;
  transition: 0.7s;
  padding-left: $l;
  padding-right: $l;
  font-weight: 600;

  &:hover,
  &.secondary {
    background: $green;
    color: $atwhite;
    border: 1px solid $green;
    border-radius: 50px;
    transition: 0.7s;
  }

  &.small {
    padding: $xs;
  }
}

span.primary,
p.primary {
  color: $atpink;
  font-weight: 600;
  font-family: $txtFamily;
}

#WEB3_CONNECT_MODAL_ID >div {
  z-index: 50;
}

.wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  &.s-wrapper {
    max-width: 850px;
  }
}

.center-center {
  justify-content: center;
  align-items: center;
}

.end-center {
  justify-content: flex-end;
  align-items: center;
}

.center-end {
  justify-content: center;
  align-items: flex-end;
}

.start-center {
  justify-content: flex-start;
  align-items: center;
}

.center-start {
  justify-content: center;
  align-items: flex-start;
}

.opacity {
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(0,0,0,0.4);
  position: absolute;
  top: 0;
  left: 0;

  &.white {
    background-color: rgba(255,255,255,0.4)
  }
}

.full-flex {
  flex: 1
}

.divider {
  width: 100%;
  display: block;
  height: 15px;
  background-color: $bg;
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .wrapper {
    width: auto;
    max-width: initial;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}