.home-page {
  
  .mint-section {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    align-items: center;
    justify-content: flex-start;
    z-index: 5;
    background-image: url('/assets/mint-bg.jpg');
    background-size: cover;
    padding-top: $xxl * 4;
    
    h1 {
      letter-spacing: 16px;
      line-height: 124px;
    }

    h2 {
      font-family: $txtFamily;
      letter-spacing: 8px;
    }

    h1, h2 {
      text-shadow: #c8c8c8 1px 1px 0px, #b4b4b4 0px 2px 0px, #a0a0a0 0px 2px 0px, rgba(140,140,140,0.5) 0px 4px 0px, #787878 0px 0px 0px, rgba(0,0,0,0.5) 0px 5px 10px;
    }

    .mint-blk {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-end;
      padding-bottom: $xxl * 3;
      
      button {
        width: 207px;
        &:hover {
          border-radius: 5px;;
          background-color: $hover-green;
          border-color: $hover-green;
          color: $atblack;
        }
      }
      
      h1 {
        text-shadow: none;
      }

      .price-row {
        display: flex;
        flex-direction: row;
        padding-bottom: $xxl;
        h1 {
          font-size: 52px;
          letter-spacing: 8px;
          line-height: 73px;
          font-family: $txtFamily;
          text-shadow: none;
        }
      }

      .mint-selection {
        padding: $l;
        min-width: 550px;
        min-height: 160px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        background-color: rgba(78, 74, 66, 0.55);
        .line {
          width: 140px;
          height: 1px;
          display: block;
          background-color: $bg;
          margin: 0 auto;
          margin-top: $xl;
        }

        // Mint num
        .mint-num {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 100px;
          button {
            width: 40px;
            height: 40px;
            padding: 0;
          }
          .mint-num-box {
            h1 {
              font-size: 30px;
              text-align: center;
              padding-left: $xxl + 10px;
              padding-right: $xxl;
              font-family: $txtFamily;
            } 
          }
        }

        p {
          padding-top: $xl;
          font-weight: 600;
        }
      }
    }
  }

  @import './intro.scss';
  @import './cert.scss';
  @import './nft-price.scss';
  @import './utilities.scss';
  @import './promo.scss';
  @import './qualifications.scss';
  @import './nft-extra.scss';
  @import './team.scss';
  @import './launch.scss';
}

@media only screen and (max-width: 600px) {
  .home-page .mint-section {
    padding-left: $l;
    padding-right: $l;
    .mint-blk {
      width: 100%;
      .mint-selection {
        min-width: inherit;
      }

      .price-row {
        align-items: center;
        h1 {
          font-size: 35px;
          letter-spacing: 8px;
          line-height: 1.4em;
        }
      }
    }
  }
  .mint-section >h1 {
    font-size: 58px;
    line-height: 1.4em !important;
  }
  .mint-section >h2 {
    font-size: 21px;
    line-height: 1.4em;
  }
}